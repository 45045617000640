<template>
  <div>
    <pdf ref="pdf" v-for="i in numPages" :key="i" :src="PDFsrc" :page="i"></pdf>
  </div>
</template>

<script>
import Vue from 'vue'
import pdf from 'vue-pdf'
import { viewAfterSignPdfApi } from '@/apis/repayment/index'
import loadingIndex from '@/utils/overlay'
Vue.use(loadingIndex)

export default {
  data() {
    return {
      msg: null,
      businessNo: '',
      tempCode: '',
      PDFsrc: '',
      numPages: null,
    }
  },
  components: { pdf },
  created() {
    this.businessNo = this.$route.query.businessNo
    this.tempCode = this.$route.query.tempCode
  },
  mounted() {
    this.reviewContract()
  },
  methods: {
    showloading() {
      var title = '加载中···'
      this.$showLoading({
        title: title,
      })
    },

    hideloading() {
      this.$cancelLoading()
    },

    reviewContract() {
      this.showloading()
      viewAfterSignPdfApi({
        businessNo: this.businessNo,
        tempCode: this.tempCode,
      })
        .then(res => {
          this.hideloading()
          const href = window.URL.createObjectURL(res)
          this.PDFsrc = href

          let loadingTask = pdf.createLoadingTask(href)
          loadingTask.promise
            .then(pdf => {
              this.numPages = pdf.numPages
            })
            .catch(err => {
              this.hideloading()
              console.error('pdf 加载失败', err)
            })
        })
        .catch(err => {
          this.hideloading()
          console.log(err)
        })
    },
  },
}
</script>

<style lang="less" scoped></style>
